import { Store } from 'vuex';
import { UserModel, TokenUserModel, StaffRolesModel } from '../../../common/src';
import { WebAppStoreState } from '@/store';
import ConfigurationService from './configuration.service';
import * as backendApi from '../shared/backend-api';

export const LOCAL_STORAGE_AUTHENTICATION_TOKEN = 'authentication-token';
export const LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN = 'authentication-sso-token';
export const LOCAL_STORAGE_AUTHENTICATION_SSO_MAPI_TOKEN = 'authentication-sso-mapi-token';
export const LOCAL_STORAGE_AUTHENTICATION_SSO_USER = 'authentication-sso-user';
export const LOCAL_STORAGE_AUTHENTICATION_SSO_COMPANY = 'authentication-sso-company';
export const LOCAL_STORAGE_AUTHENTICATION_SSO_HEADER = 'authentication-sso-header';
export default class UserService {
  constructor(public store: Store<WebAppStoreState>, public configurationService: ConfigurationService) {}

  authenticateUser(email: string, password: string): Promise<UserModel> {
    this.signOut();

    return new Promise<UserModel>((resolve, reject) => {
      let user: UserModel;
      backendApi
        .post<TokenUserModel>('user/authenticate', { email, password })
        .then((response: TokenUserModel) => {
          this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_TOKEN);
          this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN);
          this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_MAPI_TOKEN);
          this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_USER);
          this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_COMPANY);
          this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_HEADER);
          // in localstorage cause it's a normal login, should be available on all other pages as well
          localStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN, String(response.token));
          user = response.user;
          return this.configurationService.loadConfiguration(true);
        })
        .then(() => {
          this.store.commit('user', user);
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getUserById(selectedUserId: number, selectedUserCenterId: number): Promise<UserModel> {
    return backendApi.get(`user/get/${selectedUserId}/${selectedUserCenterId}`);
  }

  searchUsers(memberId?: string, name?: string, email?: string, phone?: string): Promise<UserModel[]> {
    return backendApi.get(`user/find-members`, { memberId, name, email, phone });
  }

  getStaffRoleScope(centerId: number, roles: StaffRolesModel[]): Promise<string[]> {
    return backendApi.get(`user/staff-roles-scopes`, { centerId, roles });
  }

  signOut(): void {
    this.store.commit('user', undefined);
    localStorage.removeItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN);
    this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN);
    this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_MAPI_TOKEN);
    this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_USER);
    this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_COMPANY);
    this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_HEADER);
  }

  clearStorage(name: string) {
    localStorage.removeItem(name);
    sessionStorage.removeItem(name);
  }

  account(sso: boolean, reload: boolean, reloadRelations?: boolean): Promise<UserModel> {
    if (this.store.state.user && !reload) {
      return new Promise<UserModel>(resolve => {
        resolve(this.store.state.user!);
      });
    } else {
      return new Promise<UserModel>((resolve, reject) => {
        let hasToken = false;
        let hasSSOToken = false;
        if (sso) {
          hasSSOToken = isTokenSet(sessionStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN));
        } else if (isTokenSet(localStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN))) {
          hasToken = true;
        } else {
          hasSSOToken = isTokenSet(sessionStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN));
        }
        if (hasToken || hasSSOToken) {
          backendApi
            .get<TokenUserModel>(
              'user/account',
              { reload, reloadRelations: reload && reloadRelations ? true : undefined },
              { SSO: String(sso) }
            )
            .then((response: TokenUserModel) => {
              // will be in session storage: available on this page only
              if (hasSSOToken || response.user.isSso) {
                sessionStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN, String(response.token));
              } else {
                localStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN, String(response.token));
              }

              this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN);
              this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_MAPI_TOKEN);
              this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_USER);
              this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_COMPANY);
              this.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_HEADER);
              this.store.commit('user', response.user);
              resolve(response.user);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject(undefined);
        }
      });
    }
  }
}

export function isTokenSet(token: string | null): boolean {
  return token !== null && token !== 'undefined';
}
