import router from '@/router';
import store from '@/store';
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordNormalized } from 'vue-router';
import { ConfigModel, PaymentLinkModel, UserModel, UserProfileEnum } from '../../../common/src';

export function hasAccess(route: RouteRecordNormalized | RouteLocationNormalized, user?: UserModel): boolean {
  if (!route.meta || !route.meta.access) return true;
  if (user) {
    return (
      (route.meta.access as Array<string>).includes('ANY') ||
      hasOneOfTheAccessRequired(route.meta.access as Array<string>, user.accessLevel)
    );
  }
  // user not logged in, check if the route is public per system properties
  return (store.state.config!.menu as any)[route.meta.allowAnonymousSysprop as string];
}

function hasOneOfTheAccessRequired(access: string[], accessLevels: UserProfileEnum[]): boolean {
  // we use for loop instead of forEach because we need to make sure that even if it's a string and not a list no error will be thrown.
  for (let i = 0; i < accessLevels.length; i++) {
    const accessLevel = accessLevels[i];
    if (access.includes(accessLevel)) {
      return true;
    }
  }

  return false;
}

export function visibleMenu(route: RouteRecordNormalized | RouteLocationNormalized, config: ConfigModel | undefined): boolean {
  return !route.meta || !route.meta.sysprop || Number((config?.menu as any)[String(route.meta.sysprop)]) !== 0;
}

export function getDefaultRoute(): RouteRecordNormalized | undefined {
  let result: RouteRecordNormalized | undefined;
  router.getRoutes().forEach(route => {
    if (route.path === '/') {
      result = route;
    }
  });
  return result;
}

export function getRedirectRouteFromLocalStorage(): string | null {
  let redirectRoute = localStorage.getItem('redirectRoute');
  redirectRoute = redirectRoute ? addSlashAtBeginning(redirectRoute) : null;
  const result = getMatchedRoute(redirectRoute);
  if (!result) {
    localStorage.removeItem('redirectRoute');
  }
  return redirectRoute;
}

function getMatchedRoute(selectedUrl: string | null | undefined): RouteRecordNormalized | undefined {
  if (!selectedUrl) {
    return undefined;
  }
  selectedUrl = addSlashAtBeginning(selectedUrl);
  const matchedUrls = router.resolve(selectedUrl).matched;
  return matchedUrls[0];
}

export function redirectUserAfterLogin(user: UserModel, accountJustCreated?: boolean): void {
  const redirectRoute = getRedirectRouteFromLocalStorage();
  const matchedRoute = getMatchedRoute(redirectRoute);
  localStorage.removeItem('redirectRoute');
  if (matchedRoute && hasAccess(matchedRoute, user)) {
    router.push(redirectRoute!);
  } else {
    // redirects.login should be an internal route, it doesn't make sense to redirect to an external route after login
    const matchedRoute =
      accountJustCreated && store.state.config!.redirects.createAccount
        ? getMatchedRoute(store.state.config!.redirects.createAccount)
        : getMatchedRoute(store.state.config!.redirects.login);
    if (matchedRoute && hasAccess(matchedRoute, user)) {
      router.push(matchedRoute);
    } else if (accountJustCreated) {
      router.push('/profile');
    } else {
      router.push(getDefaultRoute()!);
    }
  }
}

export function redirectToPaymentLink(paymentLinkModel: PaymentLinkModel) {
  if (paymentLinkModel.paymentUrl.postParameters) {
    openWindowWithPost(paymentLinkModel.paymentUrl.url, paymentLinkModel.paymentUrl.postParameters);
  } else {
    redirectUrl(paymentLinkModel.paymentUrl.url);
  }
}

export function redirectUrl(redirectUrl: string, replace = false): void {
  if (redirectUrl.startsWith('http')) {
    window.location.href = redirectUrl;
  } else {
    const redirectRoute = addSlashAtBeginning(redirectUrl);
    if (replace) {
      router.replace(redirectRoute);
    } else {
      router.push(redirectRoute);
    }
  }
}

function addSlashAtBeginning(routeUrl: string): string {
  return routeUrl.startsWith('/') ? routeUrl : `/${routeUrl}`;
}

export function openWindowWithPost(url: string, data: { [key: string]: string }) {
  const form = document.createElement('form');
  //form.target = '_blank'; // TODO decide whether to open in new tab or not
  form.method = 'POST';
  form.action = url;
  form.style.display = 'none';

  for (const key in data) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

const staffPattern = /(\/staff\/\d*\/\d*)\/.*/;
export function navigateToSibling(path: string, sibling: string): string {
  if (staffPattern.test(path)) {
    return path.match(staffPattern)![1] + sibling;
  }
  return sibling;
}

export const familyMemberPattern = /(\/familymembers\/\d*\/\d*)\/.*/;

export function checkRedirects(path: string, next: NavigationGuardNext): boolean {
  const outgoingRedirects = store.state.config?.redirects.outgoingRedirects;
  if (outgoingRedirects?.[path] && outgoingRedirects[path] !== path) {
    // don't redirect to yourself or you'll break the thing
    if (outgoingRedirects[path].startsWith('http')) {
      window.location.href = outgoingRedirects[path];
    } else {
      next(outgoingRedirects[path]);
    }
    return true;
  }
  return false;
}

export function addRedirectRoute(redirect: string): void {
  // if we redirect to '/', it means that we went to the login page directly from '/' (without being redirected from another page)
  // so we shouldn't override the
  if (redirect !== '/') {
    localStorage.redirectRoute = redirect;
  }
}

export function removeRedirectUrl(to: RouteLocationNormalized): void {
  const pagesWhereWeShouldKeepRedirectUrl = ['/reset-password', '/forgot-password', '/create'];
  let keep = false;
  pagesWhereWeShouldKeepRedirectUrl.forEach(page => {
    if (to.path.startsWith(page)) {
      keep = true;
    }
  });
  if (!keep) {
    localStorage.removeItem('redirectRoute');
  }
}
